import axios from 'axios'

const baseUrl = '/wp-json/';

export default {
  get(endpoint) {
    let config = {};
    return axios.get(baseUrl+endpoint, config)
    .then(response => {
      return response.data;
    });
  },
  getAuth(endpoint, token) {
    return axios.get(baseUrl+endpoint, { headers: { Authorization: 'Bearer '+token } })
    .then(response => {
      return response.data;
    });
  },
  getData(endpoint, data) {
    return axios.get(baseUrl+endpoint, data, { headers: { 'Content-Type': 'application/json' } })
    .then(response => {
      return response.data;
    });
  },
  post(endpoint, data) {
    return axios.post(baseUrl+endpoint, data)
    .then(response => {
      return response.data;
    });
  },
  postAuth(endpoint, data, token) {
    return axios.post(baseUrl+endpoint, data, { headers: { Authorization: 'Bearer '+token } })
    .then(response => {
      return response.data;
    });
  },
  put(endpoint, data) {
    return axios.put(baseUrl+endpoint, data)
    .then(response => {
      return response.data;
    });
  },
  putAuth(endpoint, data, token) {
    return axios.put(baseUrl+endpoint, data, { headers: { Authorization: 'Bearer '+token } })
    .then(response => {
      return response.data;
    });
  },
  delete(endpoint) {
    return axios.delete(baseUrl+endpoint)
    .then(response => {
      return response.data;
    });
  },
  deleteAuth(endpoint, token) {
    return axios.delete(baseUrl+endpoint, { headers: { Authorization: 'Bearer '+token } })
    .then(response => {
      return response.data;
    });
  },
  checkAuth(token) {
    return axios.post('/wp-json/jwt-auth/v1/token/validate', {}, { headers: { Authorization: 'Bearer '+token } });
  },
  login(username, password) {
    return axios.post('/wp-json/jwt-auth/v1/token', { username: username, password: password })
    .then(response => {
      console.log(response);
      if (response.data.statusCode == 403) {
        return {
          success: false,
          message: response.data.message + '<br><br>Your account will be locked for 15 minutes after 10 failed login attempts. You can <a href="/reset-password">reset your password</a> if you cannot remember it.'
        };
      }
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return {
        success: false,
        message: 'Your account has been locked for 15 minutes from too many failed login attempts. After 15 minutes, you can <a href="/reset-password">reset your password</a> or try to login again with the correct credentials.'
      };
    });
  } 
}