import Vue from 'vue'
import Videos from './Videos.vue'
import Login from './Login.vue'
import Account from './Account.vue'
import Favorite from './Favorite.vue'
import VueOverlay from '@/components/plugins/overlay'
import store from './store'

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueOverlay);

if (document.querySelector('#videos') != null) {
  new Vue({
    store,
    render: h => h(Videos),
  }).$mount('#videos');
}

if (document.querySelector('#login') != null) {
  new Vue({
    store,
    render: h => h(Login),
  }).$mount('#login');
}

if (document.querySelector('#account') != null) {
  new Vue({
    store,
    render: h => h(Account),
  }).$mount('#account');
}

if (document.querySelectorAll('.favorite').length > 0) {
  document.querySelectorAll('.favorite').forEach(favorite => {
    console.log(favorite);
    new Vue({
      store,
      render: h => h(Favorite, { props: { id: favorite.dataset.id, title: favorite.dataset.title, type: favorite.dataset.type }}),
    }).$mount('#'+favorite.id);
  });
}