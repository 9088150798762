<template>
  <section id="account">
    <div class="sidebar">
      <a v-for="tab in tabs" :key="tab" :class="{ 'current': isShow(tab.slug) }" @click="setShow(tab.slug)">{{ tab.name }}</a>
      <a @click="logout()">Logout</a>
    </div>
    <div class="workspace">
      <section class="my-account" v-if="show == 'dashboard'">
        <h4 class="text-center mb-3">Welcome Back!</h4>
        <div class="text-center content">
          <p>Manage your favorite resources here.</p>
        </div>
      </section>
      <section class="profile" v-if="show == 'profile'">
        <h4 class="text-center mb-3">Manage Profile</h4>
        <div class="text-center content">
          <p>Manage your account information here.</p>
        </div>
        <div class="content">
          <form ref="profileform" class="form" @submit.prevent="saveProfile()">
            <div class="form-item">
              <label>Email</label>
              <input class="email" type="text" v-model="profile.user_email" placeholder="Email" required />
            </div>
            <div class="form-item half">
              <label>First Name</label>
              <input class="first-name" type="text" v-model="profile.first_name" placeholder="First Name" />
            </div>
            <div class="form-item half">
              <label>Last Name</label>
              <input class="last-name" type="text" v-model="profile.last_name" placeholder="Last Name" />
            </div>
            <div class="form-item">
              <label>Change Password</label>
              <input class="password" type="text" v-model="profile.password" placeholder="Password" />
            </div>
            <div class="form-item half">
              <button class="button login" type="submit">Save</button>
            </div>
          </form>
        </div>
      </section>
      <section class="favorites" v-if="show == 'favorites'">
        <h4 class="text-center mb-3">Favorites</h4>
        <div class="content">
          <div class="favorites">
            <div class="resource-wrapper" v-if="favorites != null && favorites != false">
              <div v-for="favorite in favorites" :key="favorite" :class="'resource normal '+favorite['post_type']">
                <a class="image" :href="favorite['url']" :style="'background: url('+favorite['thumbnail']+');'">
                  <div class="spacer"></div>
                  <img :src="favorite['thumbnail']" :alt="favorite['post_title']" />
                  <div class="img" :style="'background: url('+favorite['thumbnail']+');'"></div>
                </a>
                <div class="content">
                  <a class="type"><span v-if="favorite['post_type'] == 'post'">Blog</span><span v-else>{{ favorite['post_type'] }}</span></a>
                  <h2 class="title"><a :href="favorite['url']">{{ favorite['post_title'] }}</a></h2>
                </div>
              </div>
            </div>
            <div class="message" v-else>You currently have nothing favorites. Visit a resource and click the star to save your first favorite.</div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Api from '@/Api.js'
import Helpers from '@/helpers.js'

export default {
  name: 'Account',
  data() {
    return {
      favorites: null,
      show: 'dashboard',
      tabs: [
        {
          slug: 'dashboard',
          name: 'My Account'
        },
        {
          slug: 'profile',
          name: 'Profile'
        },
        {
          slug: 'favorites',
          name: 'Favorites'
        }
      ],
      profile: null
    };
  },
  methods: {
    getFavorites() {
      Api.getAuth('api/favorites-full?user_id='+this.$store.getters.userData.id, this.$store.getters.userData.token).then(response => {
        this.favorites = response;
      });
    },
    getProfile() {
      Api.getAuth('api/profile?user_id='+this.$store.getters.userData.id, this.$store.getters.userData.token).then(response => {
        this.profile = response;
      });
    },
    isShow(show) {
      if (show == this.show) {
        return true;
      }
      return false;
    },
    setShow(newShow) {
      this.show = newShow;
    },
    logout() {
      this.$store.commit('logout');
      this.authenticateUser().then(response => {
        if (response != true) {
          window.location = '/login';
        }
      });
    },
    saveProfile() {
      // Make sure form is validate before proceeding
			if (!this.$refs.profileform.checkValidity()) {
				this.$refs.profileform.reportValidity();
        return false;
      }
      Api.postAuth('api/profile?user_id='+this.$store.getters.userData.id, { profile: this.profile }, this.$store.getters.userData.token).then(response => {
        if (response.success == true) {
          this.$overlay(true, response.message, 3000);
        }
      });
    },
    ...Helpers
  },
  mounted() {
    this.getFavorites();
    this.getProfile();
  },
  beforeMount() {
    this.authenticateUser().then(response => {
      if (response != true) {
        window.location = '/login';
      }
    });
  }
}
</script>

<style lang="scss" scoped>

</style>
