<template>
  <a class="favorite circle position-relative mr-2" @click="toggleFavorite()"><i class="fas fa-star" aria-hidden="true" v-if="isFavorite == false"></i><i class="fas fa-check" v-if="isFavorite"></i><div class="tooltip" :class="{ 'show': showTooltip }">Favorited!</div></a>
</template>

<script>
import Api from '@/Api.js'
import Helpers from '@/helpers.js'

export default {
  name: 'Favorite',
  props: [
    'id',
    'title',
    'type'
  ],
  data() {
    return {
      favorites: null,
      showTooltip: false,
      tooltipTimer: null
    };
  },
  computed: {
    isFavorite() {
      if (this.favorites == null || this.favorites.length == 0) {
        return false;
      }
      let foundFavorite = this.favorites.find(element => element == this.id);
      if (typeof foundFavorite != 'undefined') {
        this.startTooltipTimer();
        return true;
      }
      return false;
    }
  },
  methods: {
    toggleFavorite() {
      Api.postAuth('api/favorite', { user_id: this.$store.getters.userData.id, resource_id: this.id }, this.$store.getters.userData.token).then(response => {
        if (response.statusCode != 403) {
          this.getFavorites();
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'customEvent',
            'eventCategory': 'Resource Favorite',
            'eventAction': this.type+' Favorited',
            'eventLabel': this.title+' ('+this.id+')'
          });
        } else {
          window.location = '/login';
        }
      });
    },
    getFavorites() {
      Api.getAuth('api/favorites?user_id='+this.$store.getters.userData.id, this.$store.getters.userData.token).then(response => {
        this.favorites = response;
      });
    },
    startTooltipTimer() {
      this.showTooltip = true;
      this.tooltipTimer = setTimeout(() => {
        this.showTooltip = false;
      }, 1500);
    },
    ...Helpers
  },
  mounted() {
    this.getFavorites();
  },
  beforeMount() {
    
  }
}
</script>

<style lang="scss" scoped>

</style>