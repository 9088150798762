<template>
  <div id="videos">
    Test
  </div>
</template>

<script>
export default {
  name: 'Videos',
  mounted() {
    console.log(this.$store);
  }
}
</script>

<style>

</style>
