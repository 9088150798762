import Api from '@/Api.js'

export default {
  authenticateUser() {
    if (this.$store.getters.userData == false) {
      return new Promise((resolve) => {
        resolve(false);
      });
    }
    return Api.checkAuth(this.$store.getters.userData.token).then(response => {
      if (response.data.statusCode == 200) {
        return true;
      } else {
        return false;
      }
    });
  }
}