<template>
  <div id="login">
    <section id="account-chooser" class="box-shadow mx-0" v-if="show == 'account-chooser'">
      <h5 class="text-center mb-2">Register/Login</h5>
      <p class="text-center mb-3">Save your favorite recipes, articles, and get personalized content.</p>
      <div class="content white-bg p-4 d-flex align-items-center flex-column flex-lg-row">
        <div class="w-100 w-lg-50 text-center mb-5 mb-lg-0">
          <h4 class="mb-3">Have an account?</h4>
          <button class="button red-bg login" @click="setShow('login')">Login</button>
        </div>
        <div class="line" style="width: 1px; height: 60%; background: #ccc;"></div>
        <div class="w-100 w-lg-50 text-center">
          <h4 class="mb-3">Don't have an account?</h4>
          <button class="button red-bg register" @click="setShow('register')">Create Account</button>
        </div>
      </div>
    </section>
    <section id="login" v-if="show == 'login'">
      <h5 class="text-center mb-3">Login</h5>
      <form class="form" @submit.prevent="login()">
        <div class="form-item half">
          <label>Email</label>
          <input class="email" type="text" v-model="username" placeholder="Email" />
        </div>
        <div class="form-item half">
          <label>Password</label>
          <input class="password" type="password" v-model="password" placeholder="Password" />
          <div class="description mt-1">Can't remember your password? <a href="/reset-password/" target="_blank" class="font-weight-bold">Click here to reset your password.</a></div>
        </div>
        <div class="form-item half">
          <button class="button login" type="submit">Log In</button>
        </div>
        <div class="form-item text-center mt-3 font-weight-bold cursor-pointer"><a @click="setShow('register')">Don't have an account? Register now.</a></div>
      </form>
    </section>
    <section id="register-account" v-if="show == 'register'">
      <h5 class="text-center mb-3">Register</h5>
      <form ref="registerform" class="form" @submit.prevent="register()">
        <h4 class="form-item ml-1">Contact Info</h4>
        <div class="form-item half">
          <input type="text" v-model="newAccount.firstName" placeholder="First Name" required />
        </div>
        <div class="form-item half">
          <input type="text" v-model="newAccount.lastName" placeholder="Last Name" required />
        </div>
        <div class="form-item full">
          <input type="text" v-model="newAccount.email" placeholder="Email" required />
        </div>

        <h4 class="form-item mt-4">Password</h4>
        <div class="form-item half">
          <input type="password" v-model="newAccount.password" placeholder="Password" minlength="6" required />
          <div class="description">Your password must be at least 6 characters long, have at least one uppercase and one lowercase letter, and have at least one number.</div>
        </div>
        <div class="form-item half">
          <input type="password" v-model="newAccount.passwordConfirm" placeholder="Password Confirm" minlength="6" required />
        </div>
        <div class="form-item mt-2">
          <button class="button" type="submit" v-if="submitting == false">Create Account</button>
        </div>
        <div class="form-item text-center mt-3 font-weight-bold cursor-pointer"><a @click="setShow('login')">Already have an account? Click here to login.</a></div>
      </form>
    </section>
  </div>
</template>

<script>
import Api from '@/Api.js'
import Helpers from '@/helpers.js'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      newAccount: {
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        email: ''
      },
      submitting: false,
      show: 'account-chooser',
    };
  },
  methods: {
    login() {
      return Api.login(this.username, this.password).then(response => {
        if (response.success == true) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'customEvent',
            'eventCategory': 'Account',
            'eventAction': 'Login',
            'eventLabel': this.username
          });
          this.$store.commit('setUserData', response.data);
          window.location = '/my-account';
        } else {
          this.$overlay(true, 'Your username or password was incorrect. Please try again!', 3000);
        }
      });
    },
    register() {
      // Make sure form is validate before proceeding
			if (!this.$refs.registerform.checkValidity()) {
				this.$refs.registerform.reportValidity();
        return false;
      }

      if (this.newAccount.password != this.newAccount.passwordConfirm) {
        this.$overlay(true, 'Your password and confirmation password do not match.', 2000);
        return false;
      }

      if (this.newAccount.password.length < 6) {
        this.$overlay(true, 'Your password is not long enough.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/\d/) == -1) {
        this.$overlay(true, 'Your password does not contain a number.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[A-Z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an uppercase letter.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[a-z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an lowercase letter.', 2000);
        return false;
      }

      this.submitting = true;

      Api.post('account/register', this.newAccount).then(response => {
        if (response.success == true) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'customEvent',
            'eventCategory': 'Account',
            'eventAction': 'Registered',
            'eventLabel': this.newAccount.email
          });
          this.username = this.newAccount.email;
          this.password = this.newAccount.password;
          this.login().then(response => {
            if (response.success == true) {
              this.$overlay(true, 'Your account was successfully created.', 3000);
            }
          });
        } else {
          this.submitting = false;
          let message = '';
          Object.keys(response.message).forEach(key => {
            message += response.message[key];
          });
          this.$overlay(true, message, 2000);
        }
      });
    },
    setShow(newShow) {
      this.show = newShow;
    },
    ...Helpers
  },
  mounted() {
    
  },
  beforeMount() {
    this.authenticateUser().then(response => {
      if (response == true) {
        window.location = '/my-account';
      }
      console.log(response);
    });
  }
}
</script>

<style lang="scss" scoped>

</style>