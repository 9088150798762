import Overlay from './Overlay.vue'

let vm = {}
export default {
  install(Vue, opts) {
    opts = opts || {};
    const overlayPlugin = Vue.extend(Overlay);
    vm = new overlayPlugin({
      data: opts
    }).$mount();
    document.body.appendChild(vm.$el);
    Vue.prototype.$overlay = (overlay, message, timeout) => {
      vm.overlay = overlay;
      vm.message = message;
      vm.timeout = timeout;
      console.log(vm);
    }
  }
}