import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

Vue.config.devtools = true

export default new Vuex.Store({
  state: {
    userData: null
  },
  mutations: {
    setUserData(state, userData) { // eslint-disable-line
      state.userData = userData;
      Cookies.set('userData', state.userData, { expires: 1 });
    },
    logout(state) {
      Cookies.remove('userData');
      state.userData = null;
    }
  },
  getters: {
    userData(state) {
      let userData = Cookies.get('userData');
      if (typeof userData == 'undefined') {
        return false;
      }
      state.userData = userData;
      return JSON.parse(state.userData);
    }
  }
});
